import React from 'react';
import intro from '../../assets/Resources/Elements/introduction.png';
import Blur from '../UI/blur';
import { Link } from 'react-router-dom';
const IntroductionSection = () => {
  return (
    <div className='flex flex-col items-center h-screen mt-60'>
      <div className='  font-SECOND text-[150px] text-center text-fourth-color z-[1]'>
        introducing
      </div>
      <div className='z-[1] mt-[-150px]'>
        <img src={intro} alt='' className=' scale-75 mt-[-100px] ' />
      </div>
      <div className='flex flex-row items-end justify-around gap-80 mt-[-290px] z-[1]'>
        <p className=' text-5xl text-center font-PRIMARY text-fourth-color w-[450px] z-[1] mt-[-50px]'>
          osservator will monitor all the food & groceries you store, sending
          you notification before it spoils
        </p>
        <p className='text-5xl text-center font-PRIMARY text-fourth-color w-[455px] z-[1]'>
          on the Other Hand,
          <br /> it helps keeping your
          <br /> food fresh at the same time !
        </p>
      </div>
      <Link
        to='/howitworks'
        className=' bg-fourth-color text-4xl text-center p-4 w-[400px] mt-10 font-THIRD text-white transition ease-in-out hover:scale-105 z-[1] h-[300px] rounded-full'
      >
        How Does It Work
      </Link>
      <div className=' relative top-[-250px] right-[-50px] w-[300px]  z-[0]'>
        <Blur color='orange' />
      </div>
      <div className=' relative top-[-750px] right-[159px] w-[300px]  z-[0]'>
        <Blur color='green' />
      </div>
      <div className=' relative right-[700px] top-[-1300px] w-[300px]  z-[0]'>
        <Blur color='orange' />
      </div>
      <div className=' relative right-[-600px] top-[-1100px] w-[300px]  z-[0]'>
        <Blur color='green' />
      </div>
    </div>
  );
};

export default IntroductionSection;
