import React from 'react'
import img from '../../assets/Resources/Elements/Money.png'

const SmallCard = (props) => {
  return (
    <div className='relative mt-5 text-center justify-center items-center flex  w-[350px] h-[100px] overflow-hidden mb-[40px]  bg-light-color rounded-xl shadow-xl '>
        <p className='text-[50px] text-center font-PRIMARY text-white z-10 '>{props.p}</p>
        <img src={img} alt='' className='absolute  bottom-[-130px] right-[-40px] -rotate-12 opacity-30 z-0 '/>
    </div>
  )
}

export default SmallCard