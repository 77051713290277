import React, { useEffect, useState } from 'react';
import Home from './components/home/home';
import { Routes, Route, useLocation } from 'react-router-dom';
import HowItWorkScreen from './components/howItWorkScreen/howItWorkScreen';
import OrderScreen from './components/orderScreen/orderScreen';
import Faq from './components/faq/Faq';
import About from './components/faq/About';
import logo from './assets/Resources/Elements/Logo/Osservator Logo-02.svg';
import Prototype from './components/faq/Prototype';
function App() {
  const location = useLocation();

  const [showMobileWarning, setShowMobileWarning] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 1000) setShowMobileWarning(true);
  }, []);

  return (
    <div>
      {showMobileWarning === true ? (
        <>
          <div className='flex flex-col justify-center items-center font-PRIMARY '>
            <img src={logo} alt='' className='w-[200px] m-2' />
            <p>Sorry, this website is only available on desktop devices.</p>
            <p>عذراً، هذا الموقع مخصص لأجهزة الكمبيوتر</p>
          </div>
        </>
      ) : (
        <Routes key={location.pathname} location={location}>
          <Route path='/howitworks' element={<HowItWorkScreen />} />
          <Route path='/order' element={<OrderScreen />} />
          <Route path='/about' element={<About />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/' element={<Home />} />
          <Route path='/prototype' element={<Prototype />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
