import React from 'react';
import Blur from '../UI/blur';
import Img1 from '../../assets/Resources/Elements/Logo/home2.png';
import img2 from '../../assets/Resources/Elements/Logo/home3.png';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { FaPlayCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const homeSection = ({ handleClick }) => {
  return (
    <div className='flex flex-row text-left justify-between items-center h-screen mt-[-50px]'>
      <div className='flex flex-col'>
        <div>
          <div className=' absolute  lg:top-[100px] top-[50px] left-[-50px] lg:left-[-100px] opacity-40 z-[0]'>
            <Blur color='orange'></Blur>
          </div>
          <div className=' absolute  lg:top-[400px] top[200px] left-[-50px] lg:left-[-100px] opacity-40 z-[0]'>
            <Blur color='green'></Blur>
          </div>
          <h1 className=' leading-[45px] lg:leading-[90px] lg:text-9xl text-[65px] text-left lg:w-[600px] w-[300px]  text-third-color font-PRIMARY z-[4]'>
            Never WASTE
            <br />
            a&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CRUMB!
          </h1>
        </div>
        <div>
          <p className='ml-4  before:content-[""] before:w-[6px] before:absolute before:h-[80px] lg:before:left-[103px] before:left-[25px] before:bg-fourth-color  lg:text-4xl text-[25px] lg:w-[600px] w-[300px] text-left  text-sec-color font-PRIMARY z-[4]'>
            Keep track of all your groceries just by a click on your phone
          </p>
        </div>
        <div className='flex flex-col flex-wrap text-center justify-center items-center gap-4 lg:w-[300px] min-w-[300px]'>
          <Link
            to='/howitworks'
            className=' bg-fourth-color rounded-full mt-10 lg:p-2 p-1 font-PRIMARY tracking-wide lg:px-11 px-2 text-primary-color lg:text-4xl text-2xl gap-2 flex flex-row hover:scale-105 ease-in-out transition z-[2]'
          >
            Learn More
            <AiOutlineArrowRight />
          </Link>

          <Link to='/prototype' className=' bg-fourth-color rounded-full lg:p-2 p-1 font-PRIMARY tracking-wide lg:px-[50px] px-2 text-primary-color lg:text-4xl text-2xl gap-2 flex flex-row hover:scale-105 ease-in-out transition z-[2]'>
            Prototype
            <AiOutlineArrowRight />
          </Link>
          <button
            onClick={handleClick}
            className='lg:p-2 font-PRIMARY tracking-wide text-sec-color lg:text-4xl text-xl gap-2 flex flex-row justify-center content-center hover:scale-105 ease-in-out transition z-[2] '
          >
            <FaPlayCircle className=' text-fourth-color lg:text-4xl text-xl hover:scale-105 ' />
            Watch Demo
          </button>
        </div>
      </div>

      <div className=' w-[50%] flex flex-row justify-center items-right'>
        <div className='flex items-center align-middle'>
          <img
            src={img2}
            alt='hello'
            className='relative lg:top-[-50px] scale-[250%] lg:scale-100 right-[-90px] lg:right-[-80px] z-[2] '
          />
          <img
            src={Img1}
            alt='hello'
            className='relative lg:scale-75 lg:top-[100px] lg:right-[35px]  z-[1]'
          />
        </div>
        <div className=''>
          <div className=' relative lg:top-[100px] lg:right-[120px]  z-[0]'>
            <Blur color='orange'></Blur>
          </div>
          <div className=' relative lg:top-[-100px] lg:right-[330px] z-[0]'>
            <Blur color='green'></Blur>
          </div>
        </div>
      </div>
    </div>
  );
};

export default homeSection;
