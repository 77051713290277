import React from 'react';
import air from '../../assets/Resources/Elements/monitor/Untitled_ design_ 2.png';
import filter from '../../assets/Resources/Elements/monitor/FiltroCeramico.png';
const AirFilter = () => {
  return (
    <div className='mt-60 flex flex-col justify-center items-center'>
      <p className='text-7xl font-SECOND'>Air filtering system</p>
      <div className='mt-10 text-center'>
        <p className=' font-PRIMARY text-6xl text-fourth-color'>
          High Technology air filtering system that keeps all your food
        </p>
        <img src={air} alt='' className=' mt-[-100px] z-20 scale-75' />
        <div className='flex flex-row justify-between mt-[-500px] mb-[500px] font-PRIMARY text-[150px] text-fourth-color z-0 '>
          <p className='font-PRIMARY text-[150px] text-fourth-color z-0'>
            F R E S H{' '}
          </p>{' '}
          &{' '}
          <p className='font-PRIMARY text-[150px] text-fourth-color z-0'>
            H E A L T H Y
          </p>
        </div>
      </div>
      <div className='flex flex-col items-center'>
        <p className='font-PRIMARY text-6xl text-fourth-color'>
          Thanks to our Ceramic filter you will never have to buy another filter
          again
        </p>
        <img src={filter} alt='' className=' scale-75 mt-[-520px] z-20 ' />
        <p className='font-PRIMARY text-6xl text-fourth-color ml-[20px] mt-[-420px] mb-[300px]'>
          All you have to do
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          is just wash it !!
        </p>
      </div>
    </div>
  );
};

export default AirFilter;
