import React from 'react';
import Card from '..//UI/card';
import SmallCard from '../UI/SmallCard';

import earth from '../../assets/Resources/Elements/earth.svg';
import knife from '../../assets/Resources/Elements/KnF.png';
import house from '../../assets/Resources/Elements/house.svg';

const data = [
  '1.3 Tonns are wasted EACH YEAR ! which is about ⅓ of the total food in the world',
  'Nearly 34٪ of food is wasted in each household due to rotting or the poor way of storing',
  'About 4 - 10 ٪ of the inventory restaurants buy is thrown away !',
];
const data2 = ['$ 1 Trillion', '$ 2 Thousand', ' $ 10-500 Thousand '];
const FoodWasteSection = () => {
  return (
    <div className='h-screen text-center '>
      <h1 className=' text-7xl font-PRIMARY p-0 text-fourth-color '>FOOD WASTE?</h1>
      <div className='flex flex-col'>
        <div className='flex flex-row items-center justify-between text-5xl font-PRIMARY text-fourth-color'>
          <p className='w-[350px] text-center'>WORLD WIDE</p>
          <p className='w-[350px] text-center'>EACH HOUSE</p>
          <p className='w-[350px] text-center'>STORES RESTAURANTS</p>
        </div>
        <div className='flex flex-row justify-between'>
          <Card card={earth} p={data[0]} />
          <Card card={house} p={data[1]} />
          <Card card={knife} p={data[2]} />
        </div>
        <div className='flex flex-row justify-between'>
          <SmallCard p={data2[0]} />
          <SmallCard p={data2[1]} />
          <SmallCard p={data2[2]} />
        </div>
      </div>
    </div>
  );
};

export default FoodWasteSection;
