import React from 'react';
const card = (props) => {
  return (
    <div className='relative p-5 flex  w-[350px] h-[270px] overflow-hidden  bg-light-color rounded-xl shadow-2xl'>
      <p className='text-[35px] text-left font-PRIMARY text-white z-10'>{props.p}</p>
      <img
        src={props.card}
        alt=''
        className='absolute  bottom-[-100px] right-[-90px] scale-75 -rotate-12 opacity-30 z-0 '
      />
    </div>
  );
};

export default card;
