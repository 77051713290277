import React from 'react';
import table from '../../assets/Resources/Elements/OSSERVATOR WEBSITE-07.png';
const WhyUsSection = () => {
  return (
    <div className=' text-center'>
      <p className=' text-[140px] mb-[-40px] font-PRIMARY text-fourth-color'>Why US ?</p>
      <div className='flex flex-row text-center ml-[35px]'>
        <p className='text-start text-5xl font-PRIMARY mr-[30px]'>
          Osservator is
        </p>
        <p className='text-start text-5xl font-PRIMARY text-fourth-color'>
          Smarter &nbsp;&nbsp;&nbsp; Faster &nbsp;&nbsp;&nbsp; Easier <br />{' '}
          Gives all the solutions in one device
        </p>
      </div>
      <div className='mb-[60px] mt-[-90px]'>
        <img src={table} alt='' className=' scale-75' />
      </div>
    </div>
  );
};

export default WhyUsSection;
