import React, { useState, useEffect } from 'react';
import logo from '../../assets/Resources/Elements/Logo/Osservator Logo-02.svg';
import { Link } from 'react-router-dom';

const Header = () => {
  const [top, setTop] = useState(true);

  useEffect(() => {
    const scrollHandler = () => {
      window.scrollY > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <>
      <nav
        className={`max-w-auto m-auto md:px-[175px] py-2 flex items-center justify-between text-center sticky left-0 top-0 right-0  ${
          !top && ` bg-primary-color shadow-lg`
        } px-[20px] lg:px-[100px] z-[1000]  `}
      >
        <a href='/'>
          <div className='text-xl lg:text-[1.5rem] font-semibold justify-center items-center gap-1 text-primary-color-dark flex flex-row'>
            <img src={logo} alt='' className='lg:h-20 h-12' />
          </div>
        </a>
        <ul className=' mx-[20px] list-none flex items-center gap-[2rem] lg:gap-[6rem] text-sec-color'>
          <li className='li font-PRIMARY text-lg lg:text-4xl '>
            <Link to='/'>Home</Link>
          </li>
          <li className='li font-PRIMARY text-lg lg:text-4xl'>
            <Link to='/about'>About</Link>
          </li>
          <li className='li font-PRIMARY text-lg lg:text-4xl'>
            <Link to='/faq'>FAQ</Link>
          </li>
        </ul>
        <Link
          to='/order'
          className='li bg-fourth-color rounded-full p-1 lg:p-2 font-PRIMARY tracking-wide lg:px-11 px-2 text-primary-color lg:text-4xl transition ease-in-out hover:scale-105'
        >
          PreOrder Now!
        </Link>
      </nav>
    </>
  );
};

export default Header;
