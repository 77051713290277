import React from 'react';
import Header from '../UI/Header';
import HomeSection from './homeSection';
import FoodWasteSection from './FoodWasteSection';
import FoodTreeSection from './FoodTreeSection';
import IntroductionSection from './IntroductionSection';
import SlideSection from './SlideSection';
import Video from './Video';
import Savings from './Savings';
import WhyUsSection from './WhyUsSection';
import Footer from '../UI/Footer';
import { useRef } from 'react';


const Home = () => {
  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className=' bg-primary-color'>
      <Header />
      <div className='mx-[20px] lg:mx-[100px]'>
        <HomeSection handleClick={handleClick} />
        <FoodWasteSection />
        <FoodTreeSection />
        <IntroductionSection />
        <SlideSection />
        <div ref={ref}>
          <Video />
        </div>
        <Savings />
        <WhyUsSection />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
