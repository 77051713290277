import React from 'react';
import tree from '../../assets/Resources/Elements/tree.png';
import percent from '../../assets/Resources/Elements/Percentage.png';
import eight from '../../assets/Resources/Elements/Number 8.png';
import thermo from '../../assets/Resources/Elements/thermometer.png';

const FoodTreeSection = () => {
  return (
    <div className='h-screen flex flex-row '>
      <div className='w-[50%]'>
        <div className='text-7xl text-fourth-color font-PRIMARY'>
          All of this food waste is Effecting the environment
        </div>
        <div className='flex flex-row h-[200px] w-[600px] text-left'>
          <div className='flex flex-row '>
            <img src={eight} alt='' className=' ' />
            <img src={percent} alt='' className='ml-[-70px] scale-75' />
          </div>
          <p className='text-5xl font-PRIMARY text-third-color items-center flex'>
            Of Gas emissions<br/> are caused by<br/> the wasted food
          </p>
        </div>
        <div className='absolute flex flex-row w-[600px] h-[400px]  '>
          <p className='text-7xl min-w-[450px] font-PRIMARY text-sec-color '>
            Which effects the Climate change and raises the tempreture
          </p>

          <img
            src={thermo}
            alt=''
            className=' ml-[-180px] mt-[-30px] scale-[70%] '
          />
        </div>
      </div>
      <div className='w-[50%] overflow-hidden'>
        <img src={tree} alt='' className='' />
      </div>
    </div>
  );
};

export default FoodTreeSection;
