import React from 'react';
import fridge from '../../assets/Resources/Elements/monitor/fridge inside.png';
import phone from '../../assets/Resources/Elements/monitor/OSSERVATOR WEBSITE-07.png';
import phone2 from '../../assets/Resources/Elements/monitor/OSSERVATOR WEBSITE-08.png';
import phone3 from '../../assets/Resources/Elements/monitor/OSSERVATOR WEBSITE-09.png';

const monitoringSystem = () => {
  return (
    <div className=' mt-[70px] flex flex-col justify-center items-center'>
      <p className=' text-8xl font-SECOND'>Monitoring system</p>
      <div className='flex flex-row justify-between mt-11'>
        <p className='w-[500px] text-6xl font-PRIMARY text-fourth-color'>
          While the fridge door is opened the device Will keep capturing the
          fridge contents and send it to our CLOUD
        </p>
        <img src={fridge} alt='' className=' w-[60%] h-[400px] shadow-2xl' />
      </div>
      <div className='flex flex-row justify-between items-center mt-40'>
        <p className='min-w-[700px] text-7xl font-PRIMARY text-fourth-color'>
          At the Cloud, the AI will process and analyze the Pictures It will
          automatically recognize the FOOD & its quantities and it will be saved
          i the APP so the USER can check his INVENTORY !
        </p>
        <img src={phone2} alt='' className='w-[50%] scale-75 ' />
      </div>
      <div className='flex flex-row justify-between items-center mt-40'>
        <p className='min-w-[700px] text-7xl font-PRIMARY text-fourth-color'>
          The AI will also RATE the food condition and give it an estimated
          Expire / spoil date and it will be saved in the app too !
        </p>
        <img src={phone} alt='' className='w-[50%] scale-95 ' />
      </div>
      <div className='flex flex-row justify-between mt-40'>
        <img src={phone3} alt='' className='w-[50%]' />
        <div className='ml-[-150px]'>
          <p className='min-w-[700px] text-7xl font-PRIMARY text-fourth-color'>
            Then the APP will remind the USER to use the food before period of
            it Spoilage ! <br /> Giving him NOTIFICATIONS
          </p>
          <p className='min-w-[700px] pl-[200px] text-7xl font-PRIMARY text-fourth-color'>
            SHOPPING LISTS <br /> RECIPES
            <br /> DONATIONS<br/> TO FOOD BANK
          </p>
          <p className='min-w-[700px] text-7xl font-PRIMARY text-fourth-color'>
            and a lot more features
          </p>
        </div>
      </div>
    </div>
  );
};

export default monitoringSystem;
