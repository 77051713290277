import React from 'react';

const blur = (props) => {
  return (
    <div
      className={`h-[150px] w-[150px] lg:h-[300px] lg:w-[300px] lg:blur-[100px] blur-[50px] rounded-full z-[-1] ${
        props.color === 'green' ? `bg-green-600` : `bg-orange-600`
      } `}
    ></div>
  );
};

export default blur;
