import React from 'react';
import bactiria from '../../assets/Resources/Elements/monitor//becteria.png';

const bectiria = () => {
  return (
    <div className='flex flex-col text-center justify-center'>
      <p className='font-PRIMARY text-7xl text-fourth-color'>The mechanism is so Efficient, nearly 85٪ of</p>
      <div className=' px-[118px] flex flex-row justify-between' >
        <p className='font-PRIMARY text-7xl text-fourth-color'>Bacteria</p>
        <p className='font-PRIMARY text-7xl text-fourth-color'>Odors</p>
      </div>
      <img src={bactiria} alt='' className=' scale-[35%] mt-[-520px]'/>
      <p className='font-PRIMARY text-7xl text-fourth-color float-left self-start pl-[120px] mt-[-700px]'>will be</p>
      <p className='font-PRIMARY text-[200px] text-third-color mt-[-100px] z-30 '>TERMINATED</p>
      <p className='font-PRIMARY text-8xl pl-4 mt-[-100px] text-fourth-color z-30 stroke-primary-color stroke-[3px]'>Which will increase the life time of your</p>
      <p className='font-PRIMARY text-[170px] text-third-color mb-[200px] mt-[-80px] z-30'>food & groceries</p>
    </div>
  );
};

export default bectiria;
