import React from 'react';
import home from '../../assets/Resources/Elements/about/OSSERVATOR_WEBSITE_ABOUT_US.png';
import { Link } from 'react-router-dom';
import seeds from '../../assets/Resources/Elements/about/logo.png';

const About = () => {
  return (
    <>
      <img src={home} alt='' />
      <Link
        to='/'
        className='absolute bg-fourth-color rounded-full p-2 font-PRIMARY bottom-[-40px] right-0 tracking-wide px-9 mr-[10px] text-primary-color text-4xl hover:scale-105 ease-in-out transition'
      >
        Back to Home
      </Link>
      <a
        href='https://www.huawei.com/minisite/seeds-for-the-future/index.html'
        className='absolute top-0 w-[180px] mt-[10px] p-2'
        rel='noreferrer'
        target='_blank'
      >
        <img src={seeds} alt='' />
      </a>
    </>
  );
};

export default About;
