import React from 'react';
import { Link } from 'react-router-dom';

const Video = () => {
  return (
    <div className='text-center flex flex-col items-center justify-center mb-20'>
      <div className='w-[1000px] h-[500px] rounded-2xl border-8 flex justify-center items-center text-9xl font-PRIMARY text-fourth-color border-fourth-color border-solid'>
        Coming Soon. . .
      </div >
        <Link to='/howitworks' className='w-[200px] h-[50px] bg-fourth-color text-white rounded-full m-5 font-PRIMARY text-4xl transition ease-in-out hover:scale-105 flex items-center justify-center'> learn more</Link>
    </div>
  );
};

export default Video;
