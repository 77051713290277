import React from 'react';
import home from '../../assets/Resources/Elements/homedevice.png';
import indust from '../../assets/Resources/Elements/industry.png';
import Header from '../UI/Header';
import Footer from '../UI/Footer';
import { useRef } from 'react';
const OrderScreen = () => {
  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <>
      <Header />
      <div className=' mx-[40px] flex flex-col items-center justify-around'>
        <div className='text-center flex justify-around items-center '>
          <p className=' font-PRIMARY text-7xl mb-[50px] text-fourth-color'>
            Osservator comes in two options
            <br /> Pick what suits you the most!
          </p>
        </div>
        <div className='flex flex-row gap-30 justify-between'>
          <div className='w-[50%] flex flex-col items-center'>
            <div className='flex flex-row mr-[50px] items-center border-fourth-color bg-white border-4 rounded-[60px] w-[600px] h-[400px] '>
              <p className=' absolute text-sec-color font-SECOND text-8xl z-30 ml-[-60px] mt-[-300px] -rotate-6 '>
                Home Use
              </p>
              <img src={home} alt='' className=' scale-[47%] ml-[-150px]' />
              <p className='ml-[-150px] text-4xl font-PRIMARY text-fourth-color'>
                For home use and small areas Contain all the features including
                air purifying easy control plug & use
              </p>
            </div>
            <button
              onClick={handleClick}
              className='my-[30px] bg-fourth-color rounded-full p-2 font-PRIMARY tracking-wide px-11 text-primary-color text-4xl transition ease-in-out hover:scale-105'
            >
              Order
            </button>
          </div>
          <div className='w-[50%] flex flex-col items-center'>
            <div className=' flex flex-row ml-[50px] items-center border-fourth-color bg-white border-4 rounded-[60px] w-[600px] h-[400px]'>
              <p className='absolute text-sec-color font-SECOND text-8xl z-30 ml-[-60px] mt-[-300px] -rotate-6 '>
                Industrial Use{' '}
              </p>
              <img src={indust} alt='' className='scale-[47%] ml-[-150px]' />
              <p className='ml-[-150px] text-4xl font-PRIMARY text-fourth-color'>
                For industrial use <br /> such as : Stores,
                <br />
                Restaurants, Hotels
                <br /> Contain all the features
                <br /> except Air purifying
                <br /> easy control
                <br /> advanced management
              </p>
            </div>
            <button
              onClick={handleClick}
              className='my-[30px] bg-fourth-color rounded-full p-2 font-PRIMARY tracking-wide px-11 text-primary-color text-4xl transition ease-in-out hover:scale-105'
            >
              Order
            </button>
          </div>
        </div>
      </div>
      <div ref={ref} className='px-[100px]'>
        <Footer />
      </div>
    </>
  );
};

export default OrderScreen;
