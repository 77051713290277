import React from 'react';
import money from '../../assets/Resources/Elements/scale.png';
import trash from '../../assets/Resources/Elements/garbge.png';
import weight from '../../assets/Resources/Elements/scalle.png';
const Savings = () => {
  return (
    <div className='flex flex-col text-center mb-60 '>
      <p className=' text-[200px] font-PRIMARY text-fourth-color'>ANNUALLY</p>
      <p className=' text-[145px] font-PRIMARY text-fourth-color mt-[-150px]'>
        YOU WILL SAVE
      </p>
      <div className='flex flex-row justify-evenly mt-[90px]'>
        <div className='flex flex-col mr-[-200px] justify-center mt-[-280px]'>
          <p className=' text-[90px] mb-[-60px] -rotate-12 z-0 font-bold text-fourth-color font-PRIMARY'>
            80KG
          </p>
          <img src={weight} alt='' className=' scale-75 z-10  ' />
        </div>
        <img src={trash} alt='' className=' mt-[-350px] scale-[50%] z-[0]' />
        <div className='flex flex-col ml-[-200px] mb-[-300px]'>
          <p className=' text-[75px] mt-[-80px]  rotate-12 font-bold text-fourth-color font-PRIMARY'>
            500$
          </p>
          <img src={money} alt='' className=' mt-[-85px] scale-75' />
        </div>
      </div>
      <hr className='border-4 mt-[-190px] border-fourth-color z-30 ' />
    </div>
  );
};

export default Savings;
