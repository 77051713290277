import React from 'react';
import Header from '../UI/Header';
import Footer from '../UI/Footer';
import MonitoringSystem from './monitoringSystem';
import AirFilter from './AirFilter';
import Bectiria from './bectiria';
const howItWorkScreen = () => {
  return (
    <>
      <Header />
      <div className='mx-[100px]'>
        <MonitoringSystem />
        <AirFilter />
        <Bectiria/>
        <Footer />
      </div>
    </>
  );
};

export default howItWorkScreen;
