import './SlideSection.css';
import { useState } from 'react';
import Slider from 'react-slick';
import one from '../../assets/slides png/SLIDES AI-01.png';
import tow from '../../assets/slides png/SLIDES AI-02.png';
import three from '../../assets/slides png/SLIDES AI-03.png';
import four from '../../assets/slides png/SLIDES AI-04.png';
import five from '../../assets/slides png/SLIDES AI-05.png';
import six from '../../assets/slides png/SLIDES AI-06.png';
import seven from '../../assets/slides png/SLIDES AI-07.png';
import eight from '../../assets/slides png/SLIDES AI-08.png';
import nine from '../../assets/slides png/SLIDES AI-09.png';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';

const images = [one, tow, three, four, five, six, seven, eight, nine];
const NextArrow = ({ onClick }) => {
  return (
    <div className='arrow next' onClick={onClick}>
      <FaArrowRight />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className='arrow prev' onClick={onClick}>
      <FaArrowLeft />
    </div>
  );
};
function SlideSection() {
  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };

  return (
    <div className='App text-center'>
      <p className='text-8xl font-PRIMARY text-fourth-color'>
        Tons of FEATURES
      </p>
      <Slider {...settings}>
        {images.map((img, idx) => (
          <div
            className={idx === imageIndex ? 'slide activeSlide' : 'slide'}
          >
            <img src={img} alt={img} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default SlideSection;
