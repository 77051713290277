import React from 'react';
import Header from '../UI/Header';

const Prototype = () => {
  return (
    <>
    <Header />
    <div className='flex flex-col justify-center items-center  '>
    <p className=' text-5xl lg:text-7xl h-[60px] mt-[20px] font-SECOND'>Prototype</p>
      <div className='lg:w-[1000px] lg:h-[500px] rounded-2xl border-8 flex justify-center mt-10 items-center text-9xl font-PRIMARY text-fourth-color border-fourth-color border-solid'>
        <div class='sketchfab-embed-wrapper'>
          <iframe
            title='blend'
            frameborder='0'
            allowfullscreen
            mozallowfullscreen='true'
            webkitallowfullscreen='true'
            allow='autoplay; fullscreen; xr-spatial-tracking'
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            src='https://sketchfab.com/models/6cffc537557044c0a83a4191139f1f42/embed'
            className='lg:w-[984px]  lg:h-[484px] w-[400px] h-[500px] rounded-lg '
          />
        </div>
      </div>
    </div>
    </>
  );
};

export default Prototype;
