import React from 'react';
import Header from '../UI/Header';
import Footer from '../UI/Footer';
import Blur from '../UI/blur';

const Faq = () => {
  return (
    <>
      <Header />
      <div className='mx-[100px]'>
        <div className=' z-20'>
          <h1 id='title' className='text-4xl font-bold my-8'>
            <p className=' font-PRIMARY text-sec-color'>
              Questions ? Ain't no more !
            </p>
          </h1>
          <details className='bg-white border-black border-2 rounded-xl p-4 mb-4 w-[50%]'>
            <summary className='text-sec-color font-bold'>
              Does the osservator work 7/24?
            </summary>
            <p className='text-gray-600 '>
              Once you deploy and turn on the device, you will have access to
              your inventory and receive push notification anytime! But from a
              technical perspective, no, it won't continuously work. The device
              will take a picture every time the door opens, send it to the
              cloud, and there most of the work happens. For the air filtering
              system, the system works in cycles on an hourly basis to save
              battery and contain fresh and pure air!
            </p>
          </details>
          <details className='bg-white border-black border-2 rounded-xl p-4 mb-4 w-[50%]'>
            <summary className='text-sec-color font-bold'>
              How long does the battery holds up & how to recharge?
            </summary>
            <p className='text-gray-600 '>
              After a month when the filter is changed, it takes two hours for a
              full charge
            </p>
          </details>
          <details className='bg-white border-black border-2 rounded-xl p-4 mb-4 w-[50%]'>
            <summary className='text-sec-color font-bold'>
              How much does the Device costs?
            </summary>
            <p className='text-gray-600 '>
              The product has not yet been released on the market , but almost
              between $180 - $250
            </p>
          </details>
          <details className='bg-white border-black border-2 rounded-xl p-4 mb-4 w-[50%]'>
            <summary className='text-sec-color font-bold'>
              Does the device comes with a warranty ?
            </summary>
            <p className='text-gray-600 '>Yes, long-lasting</p>
          </details>
          <details className='bg-white border-black border-2 rounded-xl p-4 mb-4 w-[50%]'>
            <summary className='text-sec-color font-bold'>
              What comes in the box ?
            </summary>
            <p className='text-gray-600 '>
              osservators camera,USB charging cable,mounting tape,manual and
              warranty
            </p>
          </details>
          <details className='bg-white border-black border-2 rounded-xl p-4 mb-4 w-[50%]'>
            <summary className='text-sec-color font-bold'>
              How does the device recognize my food ?
            </summary>
            <p className='text-gray-600 '>We can recognize food by AI</p>
          </details>
          <details className='bg-white border-black border-2 rounded-xl p-4 mb-4 w-[50%]'>
            <summary className='text-sec-color font-bold'>
              Why should i buy the device ?
            </summary>
            <p className='text-gray-600 '>
              To maintain your health and comfort and improve the environment
              from your home
            </p>
          </details>

          <a href='url' className='text-sec-color hover:underline block my-4'>
            Check more FAQs
          </a>
        </div>
        <div className='absolute left-[-100px] top-[100px] z-[-1]'>
          <Blur color='green' />
        </div>
        <div className='absolute right-[-100px] top-[600px] z-[-1]'>
          <Blur color='orange' />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Faq;
